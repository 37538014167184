/*!

 =========================================================
 * Material Kit React v1.9.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition)
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
//@import "core/variables";
//@import "core/mixins";

// Core Components
//@import "core/misc";

// Core Plugins

// @import "plugins/plugin-datetime-picker";
//@import "plugins/plugin-nouislider";
//@import "plugins/plugin-react-datetime";
// @import "plugins/plugin-react-slick";

//.slider-wrapper {
//  //direction: ltr;
//}
//.slick-slide {
//  text-align: center;
//  position: relative;
//  //padding: 0 15px;
//}
//.slick-slide:focus {
//  outline: none;
//}
//.slick-slide-title {
//  text-transform: capitalize;
//}
//.slick-prev:before,
//.slick-next:before {
//  color: #777777;
//}

.bbb {
  padding: 0 15px;
}

body[dir="rtl"] .MuiSvgIcon-root {
  transform: scaleX(1) !important;
}
.MuiInput-underline:after {
  transform: scaleX(1) !important;
}
//.slick-list{
//  margin: 0 -15px;
//}

.sidebar {
  .banner {
    padding: 15px 0;
  }
}

.styles_react-code-input__CRulA > input:focus {
  outline: none;
  border: 1px solid #324149;
  caret-color: #324149;
}

// .MuiSwitch-root {
//   position: absolute !important;
//   top: 75px;
//   right: 0;
// }

.MuiTypography-body1 {
  p {
    margin-top: 0;
  }
}
.process-card-status {
  @media (max-width: 600px) {
    .MuiButton-root {
      padding: 5px 10px;
    }
  }
}
.process-card-status,
.process-card-company {
  h4 {
    font-weight: bold;
  }
  .description {
    color: #000;
  }
}
.DateRangePicker_picker {
  z-index: 2;
}
.DateRangePicker_picker__rtl {
  right: -10px;
}
.chats {
  height: 405px;
}
.chat-list {
  direction: ltr;
}
.bubble-container {
  align-items: start;
  margin-top: 15px;
  margin-bottom: 15px;
}
.new-message {
  display: none;
}
.you,
.me {
  direction: rtl;
  background-color: #dcf8c6;
  color: #000;
  &::before {
    background-color: #dcf8c6;
  }
}
.me {
  background-color: #fff;
  &::before {
    background-color: #fff;
  }
  @media (max-width: 600px) {
    margin-right: 0;
  }
}
.you {
  @media (max-width: 600px) {
    margin-left: 0;
  }
}
.chats {
  background-color: #ece5dd;
}

.customSnackbar {
  .MuiSnackbarContent-message {
    max-width: 100%;
  }
}

.buttonProgress {
  color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.dp-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dp-input {
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: block;
  height: 40px;
  font-size: 18px;
  margin-bottom: 16px;
  max-width: 120px;
  padding: 0 8px;
}

.dp-calendar {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;

  &:before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -100%);
    width: 0;
  }
}

.dp-top-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  flex-direction: row-reverse;
}

.dp-month {
  font-weight: 700;
  padding: 0 16px;
  text-align: center;
  width: 200px;
}

.dp-button {
  border: 1px solid #e4e7e7;
  border-radius: 2px;
  color: #82888a;
  cursor: pointer;
  line-height: 1;
  padding: 2px 6px;

  svg {
    display: block;
    width: 22px;

    path {
      fill: currentColor;
    }
  }

  &.focus-visible {
    border: 2px solid red;
  }
}

.dp-table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    font-size: 14px;
    text-align: center;
    user-select: none;
  }

  th {
    color: #82888a;
    font-weight: 400;
    line-height: 1.6;
  }

  td {
    cursor: pointer;
    height: 36px;
    line-height: 1;
    width: 36px;

    &:hover {
      background-color: #e4e7e7;
    }

    &.focus-visible {
      border: 2px solid red;
    }

    &:focus {
      border: 2px solid red;
    }

    &[aria-disabled="true"] {
      opacity: 0.5;
      pointer-events: none;
    }

    &[aria-selected="true"] {
      background-color: #3183c8;
      color: #fff;
      &.focus-visible {
        border: 2px solid red;
      }
    }

    &:not(:empty) {
      border: 1px solid #e4e7e7;
      &:focus {
        border: 2px solid red;
      }
    }
  }
}

.rdtPrev {
  &:focus {
    border: 2px solid red;
  }
}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #009999;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #00b3b3;
}

.enable-filter-highContrast,
.enable-filter-blackyellow {
  button {
    border: 1px solid #fff;
    z-index: 1;

    span.MuiButton-label {
      z-index: 9999999 !important;
      position: relative;
    }
  }
  .MuiInputLabel-formControl {
    z-index: 1;
  }
  .MuiFormLabel-root {
    z-index: 1;
  }
  .MuiButton-label {
    z-index: 3;
  }
  .MuiStepConnector-root {
    border: 1px solid #fff;
  }
  .MuiStepLabel-iconContainer {
    border: 1px solid #fff;
    border-radius: 50%;
  }
  .MuiStepLabel-active {
    border: 1px solid #fff;
  }
  .MuiListItemText-root {
    z-index: 1 !important;
    color: #fff !important;
    position: inherit;
  }
  .dialog-container {
    & > div > div {
      border: 1px solid #fff;
    }
    label {
      z-index: 1;
    }
  }
}

.dropzone-container {
  .focus-visible {
    border-color: #e25050 !important;
  }
  button.Mui-focusVisible {
    border: 3px solid blue;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

#content {
  padding: 30px;

  * {
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 16px;
  }
  section {
    margin-bottom: 20px;
  }
  .headerSection {
    text-align: center;
  }
  h1 {
    font-weight: bold;
    font-size: 32px;
  }
  h2 {
    font-weight: bold;
    text-decoration: underline;
  }
  h3 {
    .yearsLevelOne {
      font-weight: bold;
    }
    .textLevelOne {
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .summary {
    border-top: 1px solid #000;
    margin-top: 15px;
    padding-top: 15px;
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      padding-right: 120px;
      &::before {
        content: "\25CF";
        color: #000;
        font-size: 10px;
        margin-right: 0;
        position: relative;
        top: -2px;
        right: -10px;
      }
    }
  }
  div.levelTwo {
    margin-right: 48px;
    .text {
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .militaryServiceSection {
    .years {
      //font-weight: bold;
    }
    .text {
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .languagesSection {
    .title {
      font-weight: bold;
    }
  }

  // ul li {
  //   list-style-type: none;
  //   &:before {
  //     content: "• ";
  //     margin-left: -1em;
  //   }
  // }
}

.docx-wrapper {
  @media only screen and (max-width: 767px) {
    padding: 5px !important;
    section.docx {
      padding: 15px 5px !important;
      p.docx {
        font-size: 12px;
        line-height: 16px;
      }
      p.docx_Title {
        line-height: 22px;
        span.docx {
          font-size: 18px;
        }
      }
      p.docx_ListParagraph {
        font-size: 12px;
        line-height: 16px;
        margin-right: 58pt !important;
      }
    }
  }
}
